import React, { Component } from 'react'
import './Loader.scss'

export class Loader extends Component {
    render() {
        const {
            visible,
            showText
        } = this.props

        let loaderClassName = 'loader'
        let loaderContainerClassName = 'loader-container'

        if (showText) {
            loaderContainerClassName += ' showing-text'
        }

        if (visible) {
            loaderContainerClassName += ' visible'
        }

        return (
            <div className={loaderContainerClassName}>
                <div className={loaderClassName} />
                {showText ?
                    <span>
                        Loading...
                </span>
                    : null}
            </div>
        )
    }
}

export default Loader