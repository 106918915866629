import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import MapResult from './map-result/MapResult'
import MapMarker from './map-marker/MapMarker'
import './MapContainer.scss'
import { getImage } from 'utils/get-image'

import 'src/layouts/index.scss'
import 'components/ClinicLocations/ClinicLocations.scss'

import Alert from 'components/Alert/Alert'
import Loader from 'components/Loader/Loader'

class MapContainer extends Component {
	isMobile = false

	defaultCenter = {
		lat: -37.7662687,
		lng: 144.9616513,
	}

	defaultZoom = 17

	state = {
		results: [],
		zoomLevel: null,
		moreInfoVisible: false,
		moreInfoIndexVisible: 0,
		selectedResult: null,
		loading: true,
	}

	resultContainers = []
	mapMarkers = []
	mapInstance = null

	parseStreetAddress = location => {
		const addr = `${location['street_number']} ${location['street_name']} ${location['street_type']}, ${location['suburb']} ${location['state']} ${location['postcode']}`
		return addr
	}

	onMarkerClick = idx => {
		console.log(idx)

		this.resultContainers.forEach(cont => {
			cont && cont.close()
		})

		this.resultContainers[idx].open(true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.searchResults.length !== prevProps.searchResults.length) {
			this.recalibrateBounds()
		}
	}

	recalibrateBounds = _ => {
		let top = null
		let left = null
		let bottom = null
		let right = null

		this.props.searchResults.forEach(result => {
			const thisLatLng = result.location.point

			if (!top || thisLatLng.lat > top) {
				top = thisLatLng.lat
			}

			if (!left || thisLatLng.lon < left) {
				left = thisLatLng.lon
			}

			if (!bottom || thisLatLng.lat < bottom) {
				bottom = thisLatLng.lat
			}

			if (!right || thisLatLng.lon > right) {
				right = thisLatLng.lon
			}
		})

		if (top && left && bottom && right && this.mapInstance) {
			const bounds = {
				north: top,
				east: right,
				south: bottom,
				west: left
			}

			this.mapInstance.fitBounds(bounds)
		}
	}

	expandItem = resultIndex => {
		let moreInfoVisible = false

		if (typeof resultIndex !== 'undefined') {
			moreInfoVisible = true

			const {
				searchResults
			} = this.props

			const result = searchResults[resultIndex]

			const coords = result.location.point

			this.mapInstance.setCenter({
				lat: coords.lat,
				lng: coords.lon
			})

			this.mapInstance.setZoom(17)
		} else {
			this.recalibrateBounds()
		}

		this.setState({
			moreInfoVisible,
			selectedResult: resultIndex,
		})
	}

	parseTime = timeString => {
		let H = +timeString.substr(0, 2);
		let h = H % 12 || 12;
		let ampm = (H < 12 || H === 24) ? "AM" : "PM";
		timeString = h + timeString.substr(2, 3) + ampm;

		return timeString
	}

	getName = result => {
		if (result.clinic_name) {
			return result.clinic_name
		}

		if (result.site && result.site.name) {
			return result.site.name
		}

		return result.name
	}

	handleGoogleApiLoaded = maps => {
		this.mapInstance = maps.map
	}

	render() {
		if (typeof window != 'undefined') {
			this.isMobile = window.outerWidth <= 500 ? true : false
		}

		const {
			zoomLevel,
			moreInfoIndexVisible,
			moreInfoVisible,
			selectedResult,
		} = this.state

		const {
			searchTerm,
			searchResults,
			coords,
			loading,
			getDistanceFromUser
		} = this.props

		let sidebarClassName = 'sf-map-sidebar'

		if (moreInfoVisible) {
			sidebarClassName += ' toggle'
		}

		const result = searchResults[selectedResult] ? searchResults[selectedResult] : null

		const mapContainer = (
			<div className="sf-map-view">
				<GoogleMapReact
					bootstrapURLKeys={{ key: "AIzaSyDptJE8QzCZsetmJzepgvqRFWY0J0dt1bA" }}
					center={coords ? {
						lat: coords.latitude,
						lng: coords.longitude
					} : this.defaultCenter}
					zoom={zoomLevel}
					defaultCenter={this.defaultCenter}
					defaultZoom={this.defaultZoom}
					onGoogleApiLoaded={this.handleGoogleApiLoaded}
					yesIWantToUseGoogleMapApiInternals={true}
				>
					{searchResults.map((result, index) => {
						if (!result.location.point) {
							return null
						}

						return (
							<MapMarker
								key={index}
								lat={result.location.point.lat}
								lng={result.location.point.lon}
								text={this.getName(result)}
								result={result}
								isSelected={selectedResult === index}
								ref={marker => this.mapMarkers.push(marker)}
								data-result={JSON.stringify(result)}
								onClick={() => { this.expandItem(index) }}
							/>
						)
					})}
				</GoogleMapReact>
			</div>
		)

		const sidebar = (
			<div className={"sf-map-sidebar " + sidebarClassName} ref={ref => this.mapSidebar = ref}>
				<Loader visible={loading} showText={true} />
				<div className={"expanded-info-container hide-scrollbar"}>
					<a className="back-button" onClick={() => { this.expandItem() }}>
						&lsaquo; Back to results
					</a>
					{result ?
						<div className="content-box">
							{result.location && result.location.point ?
								<div className="distance">
									<p>
										{getDistanceFromUser(result.location.point)}
									</p>
									<img src={getImage('icons/map/marker.png')} />
								</div>
								: null}
							<h4 className={'title'}>
								{this.getName(result)}
							</h4>
							<ul className="contact-details">
								<li>
									<img src={getImage('icons/map/marker.png')} />
									<p className="sf-map-result-addr">
										{this.parseStreetAddress(result.location)}
									</p>
								</li>
								<li>
									<img src={getImage('icons/map/phone.png')} />
									<p className="sf-map-result-phone">
										{result.phones[0].number}
									</p>
								</li>
							</ul>
							<div className="bottom-section">
								<ul className="opening-hours">
									{result.opening_hours.map((day, index) => (
										<li key={index}>
											{day.day + ': ' + this.parseTime(day.open) + ' - ' + this.parseTime(day.close)}
										</li>
									))
									}
								</ul>
								<div className="divider"></div>
								{result.emails[0] ?
									<a href={'mailto:' + result.emails[0].email}>
										{result.emails[0].email}
									</a>
									: null}
								<a href={result.web} target="_blank">
									{result.web}
								</a>
								<p>
									{result.description}
								</p>
								<div className="divider"></div>
								{ /*
								<ul className="transportation-methods">
									<li>
										8 mins walk
									</li>
									<li>
										7 mins transport
									</li>
									<li>
										3 mins transport
									</li>
								</ul>
								*/
								}
							</div>
						</div>
						: null}
				</div>
				<div className="results-container hide-scrollbar">
					{searchTerm &&
						<h3 className="results-count">
							Displaying {searchResults.length} nearest clinic locations
						</h3>
					}
					{!searchTerm ?
						<Alert text={'Enter a search term to find nearby clinics.'} />
						:
						<div className="sf-map-results">
							{searchResults.map((result, index) => {
								return (
									<div key={index}>
										<MapResult
											parseStreetAddress={this.parseStreetAddress}
											result={result}
											ref={ref => this.resultContainers.push(ref)}
											onClick={() => { this.expandItem(index) }}
											parseTime={this.parseTime}
											coords={coords}
											getDistanceFromUser={getDistanceFromUser}
											getName={this.getName}
										/>
									</div>
								)
							})}
						</div>
					}
				</div>
			</div>
		)

		if (this.isMobile) {
			return (
				<div>
					<div className="sf-map-container">
						{mapContainer}
						{sidebar}
					</div>
				</div>
			)
		} else {
			return (
				<div className="sf-map-container">
					{sidebar}
					{mapContainer}
				</div>
			)
		}
	}
}

export default MapContainer