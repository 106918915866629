import React, { Component } from 'react';
import MapContainer from 'components/Map/MapContainer.js';
import LayoutWrapper from 'src/layouts/LayoutWrapper'
import SearchBox from 'components/SearchBox/SearchBox'
import { getImage } from 'utils/get-image'

import axios from 'axios'

import { geolocated } from 'react-geolocated'
import Geocode from 'react-geocode'

import './ClinicLocations.scss'

class ClinicLocations extends Component {
	isMobile = false

	state = {
		searchTerm: '',
		searchResults: [],
		defaultSuburb: null,
		loading: true,
		coords: null
	}

	componentWillMount() {
		const {
			isGeolocationAvailable,
			isGeolocationEnabled,
			positionError
		} = this.props

		if (!isGeolocationAvailable || !isGeolocationEnabled) {
			this.getLocationByIp()
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (this.props.coords && !prevProps.coords) {
			this.setState({
				coords: this.props.coords
			}, _ => {
				this.getDefaultSuburb()
			})
		} else if (!this.props.coords && this.state.coords && !prevState.coords) {
			this.getDefaultSuburb()
		}

		if (this.props.positionError && !prevProps.positionError) {
			this.getLocationByIp()
		}
	}

	getLocationByIp = _ => {
		axios.get('https://extreme-ip-lookup.com/json/').then(data => {
			this.setState({
				coords: {
					latitude: parseFloat(data.data.lat),
					longitude: parseFloat(data.data.lon)
				}
			})
		})
	}

	getDefaultSuburb = _ => {
		const {
			coords
		} = this.state

		let suburbName = null

		Geocode.setApiKey('AIzaSyDptJE8QzCZsetmJzepgvqRFWY0J0dt1bA')

		console.log('sending geocode')

		Geocode.fromLatLng(coords.latitude, coords.longitude)
			.then(response => {
				console.log('receiving geocode')
				console.log(response)

				response.results && response.results.some(result => {
					result.address_components && result.address_components.some(component => {
						if (component.types && component.types.indexOf('locality') !== -1) {
							suburbName = component.long_name

							return true // break the 'some'
						}
					})

					if (suburbName) {
						return true // break the second 'some'.
					}
				})

				this.setState({
					defaultSuburb: suburbName,
					loading: false
				})
			}, error => {
				console.log(error)
			})
	}

	handleInputChange = value => {
		this.setState({
			searchTerm: value
		})
	}

	handleSearch = _ => {
		const {
			searchTerm,
			coords
		} = this.state

		if (!searchTerm) {
			return
		}

		if (!coords) {
			alert('no coords')
			return null
		}

		const coordsString = `${coords.latitude.toFixed(3)}N${coords.longitude.toFixed(3)}E`

		const queryString = searchTerm.replace(' near me', '') // 'near me' is actually automatic, but people like using it.

		const config = {
			url: 'https://api.serviceseeker.com.au/api/v3/search',
			method: 'get',
			params: {
				q: queryString,
				location: coordsString,
				limit: 50
			},
			auth: {
				username: 'SOUMCOUNQZDOIJRSUGWAHFCNVPWAVQJV',
				password: 'BOWNJEOQFNUVVRBKYXZCRBZMBUSNVJEV'
			}
		}

		this.setState({
			loading: true
		})

		axios(config).then(res => {
			this.handleReceiveResponse(res)
		}).catch(error => {
			config.params.q += ' medical'

			axios(config).then(res => {
				this.handleReceiveResponse(res)
			})
		})
	}

	handleReceiveResponse = response => {
		this.setState({
			searchResults: this.dedupeResults(response.data.objects),
			loading: false
		})
	}

	dedupeResults = results => {
		const dedupedResults = []

		results.forEach(result => {
			if (!result.location || !result.location.point) {
				return
			}

			const resultIndexIfExists = dedupedResults.findIndex(tempResult => {
				return tempResult.web === result.web
			})

			// If there's duplicates, just use the building name (e.g Brunswick Dental Group)
			if (resultIndexIfExists !== -1) {
				if (result.location.building) {
					dedupedResults[resultIndexIfExists].clinic_name = result.location.building
				}
			} else {
				const clinicName = (result.site && result.site.name) ? result.site.name : result.name

				dedupedResults.push({
					...result,
					clinic_name: clinicName
				})
			}
		})

		return dedupedResults.sort((a, b) => {
			const aDistance = this.getDistanceFromUser(a.location.point, true)
			const bDistance = this.getDistanceFromUser(b.location.point, true)

			return aDistance - bDistance
		})
	}

	getDistanceFromUser = (point, valueOnly = false) => {
		const {
			coords
		} = this.state

		const lat1 = coords.latitude
		const lon1 = coords.longitude
		const lat2 = point.lat
		const lon2 = point.lon

		if ((lat1 == lat2) && (lon1 == lon2)) {
			return '0km'
		} else {
			var radlat1 = Math.PI * lat1 / 180
			var radlat2 = Math.PI * lat2 / 180
			var theta = lon1 - lon2
			var radtheta = Math.PI * theta / 180
			var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
			if (dist > 1) {
				dist = 1
			}
			dist = Math.acos(dist)
			dist = dist * 180 / Math.PI
			dist = dist * 60 * 1.1515
			dist = dist * 1.609344

			const roundedDist = Math.round(dist * 100) / 100

			if (valueOnly) {
				return roundedDist
			}

			return roundedDist + 'km'
		}
	}

	doExample = query => {
		this.setState({
			searchTerm: query
		}, _ => {
			this.handleSearch()
		})
	}

	render() {
		if (typeof window != 'undefined') {
			this.isMobile = window.outerWidth <= 500 ? true : false
		}

		const {
			searchTerm,
			defaultSuburb,
			searchResults,
			loading,
			coords
		} = this.state

		return (
			<LayoutWrapper>
				<div className="clinic-locations-container">
					<div className="warning">
						<div className="box">
							<img src={getImage('alert-green.png')}/>
							<p>
								If you are sick and think you might have COVID-19, visit the Australian Department of Health website
							</p>
							<a href="https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert" target="_blank">
								here
							</a>
							<p>
								.
							</p>
						</div>
					</div>
					<div className="top-container">
						<h1>
							Clinic Locator
						</h1>
						<SearchBox
							updateSearchTerm={this.handleSearch}
							handleChange={this.handleInputChange}
							searchTerm={searchTerm}
							isTopBar={this.isMobile}
							isClinicLocations={true}
							searchPlaceholder={this.props.pageContext.placeholder}
							searchButtonLabel={this.props.pageContext.button}
						/>
						{defaultSuburb ?
							<span className="subtitle">
								Examples: "
								<a 
									href="javascript:void(0)"
									onClick={_ => { this.doExample(`Women's hospital near ${defaultSuburb}`) }}
								>
									Women's hospital near {defaultSuburb}
								</a>", "
								<a href="javascript:void(0)"
									onClick={_ => { this.doExample('Dentist near me') }}
								>
									Dentist near me
								</a>".
						</span>
							: null}
					</div>
				</div>
				<MapContainer
					searchTerm={searchTerm}
					coords={coords}
					searchResults={searchResults}
					loading={loading}
					getDistanceFromUser={this.getDistanceFromUser}
				/>
			</LayoutWrapper>
		);
	}
};

export default geolocated({
	positionOptions: {
		enableHighAccuracy: false,
	},
	userDecisionTimeout: 5000,
})(ClinicLocations)