import React, { Component } from 'react'
import './Alert.scss'

export default class Alert extends Component {
    render() {
        const {
            text
        } = this.props

        return (
            <div className="alert-banner">
                {text}
            </div>
        )
    }
}
