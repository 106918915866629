import React, { Component } from 'react';
import './MapMarker.scss'

class MapMarker extends Component {
	getMarkerTypeString = _ => {

		const markerKeywords = [
			{
				string: 'hospital',
				matches: ['hospital']
			}, {
				string: 'gp',
				matches: ['medical centre', 'gp', 'general practice', 'clinic']
			}, {
				string: 'pharmacy',
				matches: ['pharmacy', 'chemist', 'pharmasave', 'shopping']
			}
		]

		let markerType = null

		markerKeywords.some(type => {
			type.matches.some(match => {
				if (name.indexOf(match) !== -1) {
					markerType = match
					return true // break some
				}
			})

			if (markerType) {
				return true // break some
			}
		})

		// default
		if (!markerType) {
			markerType = 'gp'
		}

		return markerType
	}

	render() {
		const {
			isSelected,
			onClick
		} = this.props

		const markerTypeString = this.getMarkerTypeString()

		let markerClassName = `sf-map-marker ${markerTypeString}`

		if (isSelected) {
			markerClassName += ' selected'
		}

		return (
			<div
				onClick={onClick}
				className={markerClassName}
			/>
		)
	}
}

export default MapMarker