import React, { Component } from 'react';
import './MapResult.scss'
import { getImage } from 'utils/get-image'
import moment from 'moment'

class MapResult extends Component {
	open = scroll => {
		this.setState({
			style: {
				border: '5px dashed red'
			}
		})

		if (scroll) this.ref.scrollIntoViewIfNeeded()
	}

	close = _ => {
		this.setState({
			style: {
				border: 'none'
			}
		})
	}

	getTodayOpenTimes = _ => {
		const {
			result
		} = this.props

		if (!result.opening_hours || !result.opening_hours.length) {
			return 'No opening hours'
		}

		const today = moment().format('dddd')

		let response = ''

		if (result.now_open && result.now_open) {
			response += 'Open now | '
		} else {
			response += 'Currently closed: '
		}

		if (result.opening_hours) {
			const todayHours = result.opening_hours.find(day => {
				return day.day === today
			})

			if(todayHours) {

				let startTime = String(todayHours.open)
				let closeTime = String(todayHours.close)
	
				startTime = startTime.substring(0, 5)
				closeTime = closeTime.substring(0, 5)
	
				response += this.tConvert(startTime) + ' - ' + this.tConvert(closeTime)
			} else {
				response += '24 / 7'
			}

		}

		return response
	}

	tConvert = time => {
		// Check correct time format and split into components
		time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

		if (time.length > 1) {
			time = time.slice(1)
			time[5] = +time[0] < 12 ? 'am' : 'pm'
			time[0] = +time[0] % 12 || 12
		}

		return time.join('')
	}

	render() {
		const {
			isExpanded,
			result,
			onClick,
			parseStreetAddress,
			getDistanceFromUser,
			getName
		} = this.props

		if (!result.location || !result.location.point) {
			return null
		}

		return (
			<div
				ref={ref => { this.ref = ref }}
				onClick={onClick}
				className="sf-map-result-container">
				<div className="info-container">
					<h4 className="sf-map-result-title">
						{getName(result)}
					</h4>
					<ul>
						<li>
							<img src={getImage('icons/map/marker.png')} />
							<p className="sf-map-result-addr">
								{parseStreetAddress(result.location)}
							</p>
						</li>
						{result.phones && result.phones[0] ?
							<li>
								<img src={getImage('icons/map/phone.png')} />
								<p className="sf-map-result-phone">
									{result.phones[0].number}
								</p>
							</li>
							: null}
						<li>
							<img src={getImage('icons/map/clock.png')} />
							<p>
								{this.getTodayOpenTimes()}
							</p>
						</li>
					</ul>
				</div>
				<p className="sf-map-result-bulkbill">{'Bulk Billing: ' + (!result.is_bulk_billing ? 'No' : 'Yes')}</p>
				<a href={result.web} target="_blank" className="sf-map-result-website">
					{result.web}
				</a>
				<div className="distance">
					<p>
						{getDistanceFromUser(result.location.point)}
					</p>
					<img src={getImage('icons/map/marker.png')} />
				</div>
				<a className="more-info-button">
					{!isExpanded ? 'More Info' : 'Less Info'}
				</a>
			</div>
		)
	}
}

export default MapResult